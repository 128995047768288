
body {
  margin: 0;
  font-family: 'Host Grotesk', sans-serif; /* "Host Grotesk" en priorité */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Host Grotesk', sans-serif; /* "Host Grotesk" en priorité */
}
